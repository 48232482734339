.thumbnail-container {
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 10px;
}

.thumbnail-container img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
}

.default-container {
    width: 279px;
    height: 279px;
    position: relative;
}

.default-container img {
    width: 270px;
    height: 270px;
}

.remove-icon-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@media screen {
    @media (max-width: 2048px) {
        .thumbnail-container {
            width: 170px;
            height: 170px;
        }

        .thumbnail-container img {
            width: 170px;
            height: 170px;
        }
    }
    @media (max-width: 1920px) {
        .thumbnail-container {
            width: 300px;
            height: 300px;
        }

        .thumbnail-container img {
            width: 300px;
            height: 300px;
        }
    }
    @media (max-width: 1680px) {
        .thumbnail-container {
            width: 285px;
            height: 285px;
        }

        .thumbnail-container img {
            width: 285px;
            height: 285px;
        }
    }
    @media (max-width: 1600px) {
        .thumbnail-container {
            width: 270px;
            height: 270px;
        }

        .thumbnail-container img {
            width: 270px;
            height: 270px;
        }
    }
    @media (max-width: 1440px) {
        .thumbnail-container {
            width: 240px;
            height: 240px;
        }

        .thumbnail-container img {
            width: 240px;
            height: 240px;
        }
    }
    @media (max-width: 1366px) {
        .thumbnail-container {
            width: 225px;
            height: 225px;
        }

        .thumbnail-container img {
            width: 225px;
            height: 225px;
        }
    }
    @media (max-width: 1280px) {
        .thumbnail-container {
            width: 210px;
            height: 210px;
        }

        .thumbnail-container img {
            width: 210px;
            height: 210px;
        }
    }
    @media (max-width: 1024px) {
        .thumbnail-container {
            width: 150px;
            height: 150px;
        }

        .thumbnail-container img {
            width: 150px;
            height: 150px;
        }
    }
    @media (max-width: 800px) {
        .thumbnail-container {
            width: 110px;
            height: 110px;
        }

        .thumbnail-container img {
            width: 110px;
            height: 110px;
        }
    }
}
