.textHeaderTable {
    font-size: 14px;
    font-weight: 700;
    font-family: Montserrat, serif;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    background-color: black;
    margin-right: 20px;
}

.nameBox {
    text-align: end;
    width: 20%;
    display: flex;
    margin-left: 20px;
}

.sortBox {
    width: 80px;
    margin-left: -30px;
    margin-right: 30px;
    align-self: center;
}

.linkBox {
    width: 65%;
}

.actionsRow {
    width: 10%;
    align-items: center;
    margin-right: 30px;
    text-align: center;
}

.tableRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #F0F0F0;
    margin-bottom: 10px;
}