.error-list {
    list-style: none; /* Remove default bullets */
}

.error-list li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #0B4762; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.error-list-item {
    font-weight: 400;
    font-size: .9rem;
    color: #737171;
    margin: 0 0.5rem;
    text-align: start;
}

