@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700;800&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiToolbar-gutters {
  padding-left: 14px !important;
}

.input-cubiertos::-webkit-inner-spin-button,
.input-cubiertos::-webkit-outer-spin-button {
    -webkit-appearance: none;
    }
.input-cubiertos { -moz-appearance:textfield; }

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

input[type="time"]::-webkit-calendar-picker-indicator:active {
  border: none;
}

.stepLabel .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  height: 50px !important;
  width: 50px !important;
}

.stepLabel .MuiStepIcon-root {
  color: #A6A6A6 !important;
}

.stepLabel .MuiStepIcon-root.MuiStepIcon-active {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 50% !important;
  color: #0B4762 !important;
}

.stepLabel .MuiStepIcon-text {
  font-family: Poppins, sans-serif !important;
  font-weight: 600 !important;
}

.stepLabel .MuiStepLabel-label.MuiStepLabel-active {
  font-family: Poppins, sans-serif !important;
  color: #0B4762 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.stepLabel .MuiStepLabel-label {
  font-family: Poppins, sans-serif !important;
  color: #656565 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.stepLabel .MuiStepLabel-label.MuiStepLabel-completed {
  color: #656565 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.stepLabel .MuiSvgIcon-root.MuiStepIcon-completed {
  background-color: #0B4762 !important;
  border: 2px solid #0B4762 !important;
  border-radius: 50% !important;
  color: #FFFFFF !important;
}

.MuiStepConnector-lineVertical {
  /* border-color: #D0D0D0 !important; */
  border-left-width: .2rem !important;
  margin-bottom: -4px !important;
  margin-left: 11px !important;
  margin-top: 5px !important;
  height: 70px !important;
}

p {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
h2 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
h3 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
h4 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
h5 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ul li
{
  color: #0076FF;
  list-style-type: none;
}

ul li 
{
  color: #0076FF;
  list-style: none;
  padding: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* fontSize: calc(10px + 2vmin); */
    color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiListItem-button:hover {
  background-color: #E3F2FD !important;
}

.MuiFormHelperText-root {
  text-align: right !important;
  color: #B9B9B9 !important;
}

.MuiFormControl-root {
  border: none !important;
}

.MuiButton-root:hover {
  background-color: #0B4762 !important;
}

.MuiChip-deleteIcon {
  color: #0B4762 !important;
}

/* Loader */
.MuiDialog-paperWidthSm {
  background-color: transparent !important;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.DateRangeStyles button,
.DateRangeStyles input,
.DateRangeStyles option,
.DateRangeStyles select,
.rdrMonthName {
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
}

.DateRangeStyles option {
  text-align: left;
}

.rdrDayToday .rdrDayNumber span::after {
  background: #2A2742 !important;
}

@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}

.flagContainer {
  font-family: 'NotoColorEmojiLimited', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1.2rem;
};

/* .planning-calendar.rmdp-calendar.rmdp-header.rmdp-arrow-container.rmdp-left.rmdp-arrow {
  border: solid #0B4762 !important;
}; */
