.facade {
    font-family: Poppins, sans-serif;
    border: 1px solid #DBD7DC;
    border-radius: 10px;
    background-color: white;
    position: relative;
}

.image-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    display: flex;
    padding: 0;
}

.image-container img {
    height: 450px;
    object-fit: fill;
    border-radius: 10px;
    margin-bottom: -7px;
}

.image-text {
    position: absolute;
    bottom: 20px;
    margin: 0;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: Poppins, sans-serif !important;
}

@media screen {
    @media (max-width: 2048px) {
        .facade {
            width: 1024px;
        }
        .image-container {
            width: 1024px;
        }
        .image-container img {
            width: 1024px;
        }
    }
    @media (max-width: 1920px) {
        .facade {
            width: 960px;
        }
        .image-container {
            width: 960px;
        }
        .image-container img {
            width: 960px;
        }
    }
    @media (max-width: 1680px) {
        .facade {
            width: 840px;
        }
        .image-container {
            width: 840px;
        }
        .image-container img {
            width: 840px;
        }
    }
    @media (max-width: 1600px) {
        .facade {
            width: 800px;
        }
        .image-container {
            width: 800px;
        }
        .image-container img {
            width: 800px;
        }
    }
    @media (max-width: 1440px) {
        .facade {
            width: 720px;
        }
        .image-container {
            width: 720px;
        }
        .image-container img {
            width: 720px;
        }
    }
    @media (max-width: 1366px) {
        .facade {
            width: 683px;
        }
        .image-container {
            width: 683px;
        }
        .image-container img {
            width: 683px;
        }
    }
    @media (max-width: 1280px) {
        .facade {
            width: 560px;
        }
        .image-container {
            width: 560px;
        }
        .image-container img {
            width: 560px;
        }
    }
    @media (max-width: 1024px) {
        .facade {
            width: 512px;
        }
        .image-container {
            width: 512px;
        }
        .image-container img {
            width: 512px;
        }
    }
    @media (max-width: 800px) {
        .facade {
            width: 400px;
        }
        .image-container {
            width: 400px;
        }
        .image-container img {
            width: 400px;
        }
    }
}
