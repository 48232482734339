.row {
    display: flex;

    margin-top: 5px;
    margin-bottom: 5px;
}

.imageContainer {
    width: 100%;
    max-width: 100px;
    height: 100px;
    padding: 5px;
    border-radius: 15px;
}

.productImage {
    width: 100%;
    max-width: 100px;
    border-radius: 5px;
    margin: auto;
}

.productContainer {
    width: 100%;
    display: flex;
}

.textContainer {
    width: 60%;
    display: inline;
    justify-content: space-between;
}

.titleContainer {
    display: inline;
}

.title {
    padding: 0;
    margin: 0;
}

.descriptionContainer {
    display: inline;
}

.description {
    padding: 0;
    margin: 0;
    color: #999999;
}

.price {
    width: 40%;
    vertical-align: middle;
    text-align: end;
    margin-right: 1em;
}