body {
    background: #EFEFEF;
    overflow: scroll;
}

#content {
    width: 100%;
}

/* .react-grid-layout {
  background: #eee;
} */
.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}

.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}

.react-grid-item {
    box-sizing: border-box;
    cursor: grab;
    /* background-image: linear-gradient(to bottom, rgb(81 148 13) 0%, rgb(71 176 21) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%) !important;
    background-clip: content-box, padding-box !important;
    border: none !important; */
    padding: 10px;
}

.react-grid-item:active {
    cursor: grabbing;
}

/* .react-grid-item:not(.react-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
} */
.react-grid-item.resizing {
    opacity: 0.9;
}

.react-grid-item.static {
    background: #cce;
}

.react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
}

.react-grid-item .minMax {
    font-size: 12px;
}

.react-grid-item .add {
    cursor: pointer;
}

.react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

li b {
    font-size: 19px;
    line-height: 14px;
}

/**Clases propias**/

.react-grid-inner-table, .react-out-table {
    background-color: white;
    box-sizing: border-box;
    border-radius: 8px;
    border: 2px solid #0B4762;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #0B4762;
    cursor: pointer;
    height: 4em;
    width: 4em;
}

.gray-table {
    color: #B2B2B2;
    border: 2px solid #B2B2B2;
}

.selected-table {
    color: white;
    border: 2px solid #0B4762;
    background: #0B4762;
}

.disabled-table {
    color: #B2B2B2;
    border: 2px solid #B2B2B2;
    background: #DBD7DC;
}

.circular {
    border-radius: 50%;
}

.dragging-item {
    background-color: #00AA68b9 !important;
}

.droppable-element {
    /* width: 3em;
    height: 3em;
    text-align: center;
    background: #fdd;
    border: 1px solid black;
    border-radius: 1em;
    margin: 10px 0;
    padding: 10px;
    /* cursor: pointer; */
    /* background-color: transparent;
    position: relative; */
}

.droppable-element:active {
    cursor: grabbing;
}

#borderLeft {
    position: absolute;
    top: 20%;
    left: 0px;
    height: 60%;
    width: 2px;
    background-color: black;
}

#borderTop {
    position: absolute;
    top: 0;
    left: 20%;
    height: 2px;
    width: 60%;
    background-color: black;
}

#borderBottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    height: 2px;
    width: 60%;
    background-color: black;
}

#borderRigth {
    position: absolute;
    top: 20%;
    right: 0px;
    height: 60%;
    width: 2px;
    background-color: black;
}
